import { BASE_URL_MANAGER, BASE_URL_ORCHESTATOR } from '@/config';
import { store } from '@/app/store';
import Axios from 'axios';
import { Component } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import AdminNavbar from '@/app/components/admin-navbar/AdminNavbar.vue';
import NavBar from '@/app/components/shared/nav-bar/NavBar.vue';
import FacebookButton from '@/app/components/facebook-button/FacebookButton.vue';
import TokenDropi from '@/app/components/token-dropi/TokenDropi.vue';

import axios from 'axios';
import ITokenDropi from '@/app/components/token-dropi/TokenDropi.1';
import { IProject } from '@/app/store/modules/user/user.models';

@Component({
  name: 'projects-management',
  components: { AdminNavbar, NavBar, FacebookButton, TokenDropi },
})
export default class ProjectsManagement extends VueWizard {
  public visible = false;
  public addControl = false;
  public modalShowFlow = false;
  public formLayout = 'horizontal';
  public nameVal = '';
  public projectId = '';
  public projectFlows = '';
  public projectTopics = '';
  public topiContent: {
    id: string;
    nodeList: any[];
    name: string;
    project: string;
    createdAt: string;
    updatedAt: string;
  }[] = [];
  public conversations = '';
  public currentProject: IProject = {
    _id: '',
    country: '',
    bussinesStatusList: [],
    dropiUsers: [],
    accessToken: '',
    phone_number_id: '',
    bussinnes_account_id: '',
  };
  public selectedWhatsappNumber: any = {};
  public whatsappNumbers: any[] = [];
  public isNew = false;

  get tokenDropiComponent(): ITokenDropi[] {
    return this.currentProject.dropiUsers;
  }

  newProyect(value: any): void {
    Axios.post(
      `${BASE_URL_MANAGER}/project/${
        store.state.user ? store.state.user.user.company!._id : ''
      }`,
      { name: value },
    ).then(() => {
      this.addControl = false;
      this.nameVal = '';
      this.$notification.success({
        placement: 'bottomRight',
        message: 'Datos guardados con exito!',
        description: 'Los datos del proyecto han sido guardados',
      });
    });
  }

  cancelNewProyect(): void {
    this.addControl = false;
  }

  async showProjectDetails(projectId: any): Promise<void> {
    if (isNaN(projectId)) {
      const urls = [
        `${BASE_URL_MANAGER}/project/${projectId}`,
        `${BASE_URL_MANAGER}/flow/${projectId}`,
        `${BASE_URL_MANAGER}/topic/${projectId}`,
      ];

      try {
        const [resProject, resProjectFlows, resProjectTopics] =
          await Promise.all(urls.map(url => Axios(url)));

        this.currentProject = resProject.data.project;
        this.isNew = resProject.data.isNew;

        if (
          !this.currentProject.dropiUsers ||
          this.currentProject.dropiUsers.length === 0
        ) {
          this.currentProject.dropiUsers = [{ token: '' }];
        }

        this.topiContent = resProjectFlows.data.projectFlows;
        this.projectFlows = resProjectFlows.data.projectFlows.map(
          (prFlow: any) => prFlow.name,
        );
        this.projectTopics = resProjectTopics.data.projectTopics.map(
          (prTopic: any) => prTopic.name,
        );

        this.getConversations(projectId);
        this.projectId = projectId;
        this.visible = true;
      } catch (err) {
        throw err;
      }
    }
  }

  getConversations(projectId: any): void {
    const url = `${BASE_URL_ORCHESTATOR}/conversation/${projectId}`;
    Axios(url, {
      params: {
        limit: 10,
        pagenum: 0,
      },
    })
      .then(res => {
        this.conversations = res.data;
      })
      .catch(err => {
        throw err;
      });
  }

  handleSubmit(): void {
    const option: any = this.$refs.option;
    let url = `${BASE_URL_MANAGER}/project/${this.projectId}/initialFlow/${option.value}`;
    Axios.patch(url)
      .then(res => {
        this.$notification.success({
          placement: 'bottomRight',
          message: 'Proyecto actualizado!',
          description: `El flujo del projecto ${res.data.project}, se ha modificado con exito!`,
        });
      })
      .catch(err => {
        this.$notification.error({
          placement: 'bottomRight',
          message: 'Ocurrio un error!',
          description: `El flujsaso ${option.value} no se ha modificado!`,
        });
        throw err;
      });
  }

  handleSubmitDropi(): void {
    const option: any = this.$refs.option;
    let url = `${BASE_URL_MANAGER}/project/${this.projectId}`;

    this.currentProject.phone_number_id = this.selectedWhatsappNumber.chanel;
    this.currentProject.bussinnes_account_id =
      this.selectedWhatsappNumber.namespace;
    this.currentProject.accessToken = this.selectedWhatsappNumber.access_key;
    this.currentProject.selectedWhatsappNumber = this.selectedWhatsappNumber.id;
    this.currentProject.integrator = this.selectedWhatsappNumber.integrator;

    Axios.put(url, this.currentProject)
      .then(res => {
        // this.$notification.success({
        //   placement: "bottomRight",
        //   message: "Proyecto actualizado!",
        //   description: `El projecto ${res.data.project.name}, se ha modificado con exito!`,
        // });
        // this.getDropiWhatsappNumbers();
      })
      .catch(err => {
        this.$notification.error({
          placement: 'bottomRight',
          message: 'Ocurrio un error!',
          description: `El flujos ${err} no se ha modificado!`,
        });
        throw err;
      });
  }

  get projectsInfo() {
    return store.state.admin!.project;
  }

  addToken(): void {
    // add component tokenDropi
    this.tokenDropiComponent.push({
      token: '',
      country: '',
      name: '',
    });
  }
}
