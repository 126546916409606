import { Component, Vue, Prop } from 'vue-property-decorator';
import axios, { AxiosError } from 'axios';
import { BASE_URL_MANAGER, FACEBOOK_ENVIRONMENT } from '@/config';
import Swal from 'sweetalert2';
@Component({
  name: 'facebook-button',
})
export default class FacebookButton extends Vue {
  @Prop() public project!: any;
  public layout = 'column';
  public labelCol = { span: 4 };
  public wrapperCol = { span: 14 };

  public isTokenPermenent: boolean | null = null;
  public isTokenUser: boolean | null = null;

  // Steps
  public steps = [
    {
      title: 'Seleccionar Negocio',
    },
    {
      title: 'Seleccionar Cuenta de Negocio',
    },
    {
      title: 'Seleccionar Número de Teléfono',
    },
    {
      title: 'Confirma tu información',
    },
  ];

  public stepsManually = [
    {
      title: 'Cuenta de negocio',
    },
    {
      title: 'ID del numero de teléfono',
    },
    {
      title: 'Token facebook',
    },
    {
      title: 'Confirma tu información',
    },
  ];

  public facebookName: string = '';
  public facebookPicture: {
    data: {
      height: number;
      is_silhouette: boolean;
      url: string;
      width: number;
    };
  } = {
    data: {
      height: 0,
      is_silhouette: false,
      url: '',
      width: 0,
    },
  };
  public facebookUrl: string = '';
  public facebookToken: {
    accessToken: string;
    expiresIn: string;
    signedRequest: string;
    userID: string;
    data_access_expiration_time: number;
    graphDomain: string;
  } = {
    accessToken: '',
    expiresIn: '',
    signedRequest: '',
    userID: '',
    data_access_expiration_time: 0,
    graphDomain: '',
  };
  public facebookId: string = '';
  public selectedBusiness: string = '';
  public enableBusiness: boolean = false;
  public enableBusinessAccount: boolean = false;
  public loading: boolean = false;
  public enablePhoneNumber: boolean = false;
  public existToken: boolean = false;
  public showAlert: boolean = false;
  public selectedPhoneNumberId: string = '';
  public selectedBusinessAccount: string = '';

  public businesses: {
    id: string;
    name: string;
  }[] = [];

  public businessAccounts: {
    id: string;
    name: string;
  }[] = [];

  public phoneNumbers: {
    id: string;
    display_phone_number: string;
  }[] = [];

  // model for the form
  public form: {
    business: string;
    businessAccount: string;
    phoneNumber: string;
  } = {
    business: '',
    businessAccount: '',
    phoneNumber: '',
  };

  public async mounted() {
    await this.loadFacebookSdk();
    await this.initFacebook();
    await this.checkToken();
  }

  connectWithUserToken() {
    // switch between true and false
    this.isTokenUser = !this.isTokenUser;
    // if isTokenPermenent is true, then switch to false
    if (this.isTokenPermenent) {
      this.isTokenPermenent = false;
    }
  }

  connectWithPermanentToken() {
    // switch between true and false
    this.isTokenPermenent = !this.isTokenPermenent;
    // if isTokenPermenent is true, then isTokenUser is false
    if (this.isTokenUser) {
      this.isTokenUser = false;
    }
  }

  async getLoginStatus() {
    window.FB.getLoginStatus((response: any) => {
      if (response.status === 'connected') {
        this.facebookToken = response.authResponse;
        this.facebookId = response.authResponse.userID;
      }
    });
  }

  // public checkToken;

  public async checkToken() {
    type DataAccessToken = {
      accessToken: string;
      expiresIn: string;
      expirationTime: string;
      bussinnes_account_id: string;
      phone_number_id: string;
      data_access_expiration_time: number;
    };

    const response = await axios.get<DataAccessToken>(
      `${BASE_URL_MANAGER}/token/project`,
      {
        params: {
          projectID: this.project._id,
        },
      },
    );

    // save the token in the local storage
    if (response.status === 200 && response.data.accessToken.length > 0) {
      const token = response.data.accessToken;
      const expiresIn = response.data.expiresIn;
      const expirationTime = response.data.expirationTime;
      // timestamp to date
      const dataAccessToken = {
        accessToken: token,
        expiresIn: expiresIn,
        expirationTime,
      };

      // renovate token 1 day before expiration
      // if (expireIn !== 0 && date.getTime() - new Date().getTime() < 86400000) {
      //   this.renovateToken(token, bussiness_account_id, phone_number_id);
      // }
      const base64Token = btoa(JSON.stringify(dataAccessToken));
      this.getBasicInformation(token);

      localStorage.setItem('token:facebook', base64Token);
      this.showAlert = true;
    } else {
      localStorage.removeItem('token:facebook');
    }
  }

  async getBasicInformation(access_token: string) {
    const response = await axios.get(BASE_URL_MANAGER + '/token/user', {
      params: {
        access_token: access_token,
      },
    });
    this.facebookName = response.data.name;
    this.facebookPicture = response.data.picture;
  }

  async logInWithFacebook() {
    window.FB.login(
      (response: any) => {
        if (response.authResponse) {
          this.facebookToken = response.authResponse;
          this.facebookId = response.authResponse.userID;
          this.getDataUser();
        }
      },
      {
        config_id: '1763191020743989',
      },
    );
  }

  async getDataUser() {
    window.FB.api(
      '/me',
      { fields: 'id,first_name,last_name,businesses' },
      (response: any) => {
        if (response) {
          this.businesses = response.businesses.data;
          this.existToken = true;
        }
      },
    );
  }
  async handleChangesBusiness() {
    window.FB.api(
      `/${this.selectedBusiness}`,
      { fields: 'owned_whatsapp_business_accounts' },
      (response: any) => {
        if (response) {
          this.businessAccounts =
            response.owned_whatsapp_business_accounts.data;
        }
      },
    );
  }

  async handleChangesBusinessAccount() {
    window.FB.api(
      `/${this.selectedBusinessAccount}`,
      { fields: 'phone_numbers' },
      (response: any) => {
        if (response) {
          this.phoneNumbers = response.phone_numbers.data;
        }
      },
    );
  }
  async handleChangesPhoneNumberId() {
    if (this.selectedPhoneNumberId) {
      this.enablePhoneNumber = true;
    } else {
      this.enablePhoneNumber = false;
    }
  }

  async handleRevokeToken() {
    this.loading = true;
    window.FB.api(
      '/me/permissions',
      'DELETE',
      {
        access_token: this.facebookToken.accessToken,
      },
      async (response: any) => {
        await this.handleRemoveToken();
        localStorage.removeItem('token:facebook');
        this.existToken = false;
        if (response) {
          this.showAlert = false;
          this.loading = false;
        }
      },
    );
  }

  async handleRemoveToken() {
    axios.delete(`${BASE_URL_MANAGER}/token/project/${this.project._id}`);
  }

  async verifyToken() {
    window.FB.api(
      '/me',
      {
        fields: 'id,first_name,last_name,businesses',
      },
      (response: any) => {
        if (response) {
          console.log(response);
        } else {
          this.existToken = false;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Token is invalid',
          });
        }
      },
    );
  }
  // negate the value of enableBusiness
  handleChangesEnableBusiness() {
    if (this.selectedBusiness == '') {
      return;
    }
    this.enableBusiness = !this.enableBusiness;
  }

  // clear the value of selectedBusiness
  handleChangesClearBusiness() {
    this.selectedBusiness = '';
    this.enableBusiness = !this.enableBusiness;
  }

  // negate the value of enableBusinessAccount
  handleChangesEnableBusinessAccount() {
    if (this.selectedBusinessAccount == '') {
      return;
    }
    this.enableBusinessAccount = !this.enableBusinessAccount;
  }

  // clear the value of selectedBusinessAccount
  handleChangesClearBusinessAccount() {
    this.selectedBusinessAccount = '';
    this.enableBusinessAccount = !this.enableBusinessAccount;
  }

  // negate the value of enablePhoneNumber
  handleChangesEnablePhoneNumber() {
    this.enablePhoneNumber = !this.enablePhoneNumber;
  }

  // clear the value of selectedPhoneNumberId
  handleChangesClearPhoneNumber() {
    this.selectedPhoneNumberId = '';
    this.enablePhoneNumber = !this.enablePhoneNumber;
  }

  validateInputs(): boolean {
    return !!(
      this.selectedBusiness &&
      this.selectedBusinessAccount &&
      this.selectedPhoneNumberId
    );
  }

  async handleChangesSave() {
    if (!this.validateInputs()) {
      return;
    }
    const data = {
      facebookToken: this.facebookToken,
      businessId: this.selectedBusiness,
      bussinnes_account_id: this.selectedBusinessAccount,
      phone_number_id: this.selectedPhoneNumberId,
      project: this.project._id,
    };
    try {
      const response = await axios.post(`${BASE_URL_MANAGER}/token/`, data);

      if (response) {
        this.existToken = true;
        this.showAlert = true;
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Token saved',
        });
      }
    } catch (error) {
      const { message } = error as AxiosError;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: message,
      });
    }
  }
  async handleChangesSaveManual() {
    // add data_access_expiration_time, graph_domain, expriresIn, signedRequest, userID to this.facebookToken
    this.facebookToken.data_access_expiration_time = 5184000;
    this.facebookToken.graphDomain = 'facebook';
    this.facebookToken.expiresIn = '0';
    this.facebookToken.signedRequest = 'signedRequest';
    this.facebookToken.userID = 'userID';

    const data = {
      facebookToken: this.facebookToken,
      businessId: '',
      bussinnes_account_id: this.form.businessAccount,
      phone_number_id: this.form.phoneNumber,
      project: this.project._id,
    };

    try {
      const response = await axios.post(`${BASE_URL_MANAGER}/token/`, data);

      if (response) {
        this.existToken = true;
        this.showAlert = true;
        this.isTokenPermenent = false;
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Token saved',
        });
      }
    } catch (error) {
      const { message } = error as AxiosError;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: message,
      });
    }
  }

  async renovateToken(
    access_token: string,
    bussinnes_account_id: string,
    phone_number_id: string,
  ) {
    const data = {
      project: this.project._id,
    };

    try {
      const response = await axios.post(
        `${BASE_URL_MANAGER}/token/refresh`,
        data,
      );

      if (response) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Token saved',
        });
      }
    } catch (error) {
      const { message } = error as AxiosError;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: message,
      });
    }
  }

  async initFacebook() {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: FACEBOOK_ENVIRONMENT.appId,
        xfbml: false,
        version: 'v16.0',
        status: true,
        cookie: true,
      });
      window.FB.AppEvents.logPageView();
    };
  }

  async loadFacebookSdk() {
    return new Promise((resolve, reject) => {
      // check if facebook sdk is already loaded
      if (document.getElementById('facebook-jssdk')) {
        resolve(true);
      } else {
        const id = 'facebook-jssdk';
        const fjs = document.getElementsByTagName('script')[0];
        if (document.getElementById(id)) {
          resolve(true);
          return;
        }
        const js = document.createElement('script');
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        js.onload = resolve;
        js.onerror = reject;
        fjs.parentNode!.insertBefore(js, fjs);
      }
    });
  }
  // handler local storage token Facebook
  async handlerToken() {
    const token = localStorage.getItem('token:facebook');
    if (token) {
      // token base64 decode
      const decodedToken = atob(token);
      // token json parse
      this.verifyToken();
    }
  }

  // handler steps
  currentStep = 0;

  nextStep1() {
    this.currentStep++;
    this.selectedBusinessAccount = '';
  }
  nextStep2() {
    this.currentStep++;
    this.selectedPhoneNumberId = '';
  }
  nextStep3() {
    this.currentStep++;
  }

  prevStep1() {
    this.currentStep--;
    this.selectedBusiness = '';
  }
  prevStep2() {
    this.currentStep--;
    this.selectedBusinessAccount = '';
  }
  prevStep3() {
    this.currentStep--;
    this.selectedPhoneNumberId = '';
  }

  // handler steps manual
  currentStepManual = 0;
  nextStepManual1() {
    this.currentStepManual++;
    this.selectedBusinessAccount = '';
  }
  nextStepManual2() {
    this.currentStepManual++;
    this.selectedPhoneNumberId = '';
  }
  nextStepManual3() {
    this.currentStepManual++;
    this.selectedPhoneNumberId = '';
  }
  nextStepManual4() {
    this.currentStepManual++;
  }
  prevStepManual1() {
    this.currentStepManual--;
    this.selectedBusiness = '';
  }
  prevStepManual2() {
    this.currentStepManual--;
    this.selectedBusinessAccount = '';
  }
  prevStepManual3() {
    this.currentStepManual--;
    this.selectedPhoneNumberId = '';
  }
  prevStepManual4() {
    this.currentStepManual--;
  }
}
