import { Component, Prop } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';

import Axios from 'axios';

import { BASE_URL_MANAGER } from '@/config';
import { reactive } from 'vue';

import ITokenDropi from './TokenDropi.1';

@Component({ name: 'token-dropi' })
export default class TokenDropi extends VueWizard {
  @Prop({ default: () => ({}) }) public currentProject: any;
  @Prop({ default: () => ({}) }) public projectId: any;
  @Prop({ default: () => ({}) }) public index: any;
  @Prop({ default: () => ({}) }) public item: any;

  public layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
  };

  public countries = [
    { label: 'Colombia', value: 'CO' },
    { label: 'Ecuador', value: 'EC' },
    { label: 'Panama', value: 'PA' },
    { label: 'Mexico', value: 'MX' },
    { label: 'Peru', value: 'PE' },
    { label: 'Chile', value: 'CL' },
    { label: 'España', value: 'ES' },
    { label: 'Paraguay', value: 'PY' },
  ];

  public formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };
  public visible = false;
  public dynamicValidateForm = reactive({ token: '', country: '', name: '' });
  public confirmLoading = false;
  public enableSubmit = false;

  // rules form
  public tokenFormRules: any = {
    token: [
      { required: true, message: 'El token es requerido', trigger: 'change' },
    ],
    country: [
      { required: true, message: 'El pais es requerido', trigger: 'change' },
    ],
    name: [
      { required: true, message: 'El nombre es requerido', trigger: 'change' },
    ],
  };
  onValTokenDropi(prop: string, valid: boolean) {
    if (!valid) {
      if (this.tokenDropi.country !== '' && this.tokenDropi.name !== '')
        this.enableSubmit = false;
    } else {
      this.enableSubmit = true;
    }
  }

  get validateFormTokenDropi() {
    return (
      this.tokenDropi.token === '' ||
      this.tokenDropi.country === '' ||
      this.tokenDropi.name === ''
    );
  }

  get tokenDropi(): ITokenDropi {
    return this.item;
  }

  cleanTokenDropi(): void {
    this.tokenDropi.token = '';
    this.tokenDropi.country = '';
    this.tokenDropi.name = '';
  }

  minusTokenDropi(index: number): void {
    this.currentProject.dropiUsers.splice(index, 1);
  }

  showModal(): void {
    if (this.tokenDropi.token === '') {
      this.minusTokenDropi(this.index);
    }
    this.visible = true;
  }

  async handleSubmitDropi() {
    let url = `${BASE_URL_MANAGER}/project/${this.projectId}`;
    await Axios.put(url, this.currentProject)
      .then(res => {
        this.$notification.success({
          placement: 'bottomRight',
          message: 'Proyecto actualizado!',
          description: `El projecto ${res.data.project.name}, se ha modificado con exito!`,
        });
      })
      .catch(err => {
        console.log(err);

        this.$notification.error({
          placement: 'bottomRight',
          message: 'Ocurrio un error!',
          description: `El flujo no se ha modificado!`,
        });
        throw err;
      });
  }

  async handleOk() {
    this.visible = false;
    this.minusTokenDropi(this.index);
    this.confirmLoading = true;
    await this.handleSubmitDropi();
    this.confirmLoading = false;
  }
}
